const string = {
    
  /*********약관 등**********/
  policyView: '약관보기',
  needAgreement: '약관에 동의해 주세요',
  policyTitle: '개인정보 수집 및 이용약관',
  policyTitle2: '(필수) 개인정보 수집 및 이용약관',
  policyContent: [
    { title: '제1장 총칙', content: '' },
    {
      title: '제1조 (목적)',
      content:
        '이 약관은 BITLIVE(이하: 회사)이 제공하는 앱 및 지갑에서 제공하는 서비스(이하: 서비스)의 이용조건 및 절차에 관한 회사와 사용자 간의 권리 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.\n',
    },
    {
      title: '제2조 (약관의 명시, 설명과 개정)',
      content:[
        '① 이 약관의 내용은 회사의 서비스 등 기타의 방법으로 사용자에게 공지하고, 사용자가 이 약관에 동의함으로써 효력을 발생합니다.\n',
        '② 회사는 “약관의 규제에 관한 법률”, “정보통신망 이용촉진 및 정보보호 등에 관한 법률” 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.\n',
        '③ 회사가 약관을 개정할 경우에는 적용일자 및 개정 사유를 명시하여 현행 약관과 함께 회사 사이트의 초기화면이나 팝업 화면 또는 공지사항으로 그 적용일자 7일 이전부터 공지합니다. 다만, 사용자에게 불리하거나 중대한 내용의 변경의 경우에는 적용일자 30일 이전에 사용자에게 알립니다.\n',
        '④ 회사가 전항에 따라 개정약관을 공지하면서 사용자에게 7일간의 기간 내에 의사 표시를 하지 않으면 의사표시가 표명된 것으로 본다는 뜻을 명확하게 공지하였음에도 사용자가 명시적으로 거부의 의사표시를 하지 아니한 경우 사용자가 개정약관에 동의한 것으로 봅니다.\n',
        '⑤ 사용자가 개정약관의 적용에 동의하지 않는다는 명시적 의사를 표명한 경우 회사는 개정 약관의 내용을 적용할 수 없으며, 이 경우 사용자는 이용계약을 해지할 수 있습니다. 다만, 기존 약관을 적용할 수 없는 특별한 사정이 있는 경우에는 회사는 이용계약을 해지할 수 있습니다.\n',
      ]
    },
    {
      title: '제3조 (약관 외 준칙)',
      content:
        '회사는 서비스에 대하여 이 약관 외에 별도의 공지사항, 개별 약관, 개별 동의 사항, 이용안내, 운영정책, 세부지침 등(이하 통틀어 “세부지침”이라 합니다)을 정하여 이를 사용자에게 알리거나 동의를 받을 수 있으며, 세부지침 중 사용자의 동의를 받은 내용이 이 약관과 상충할 경우에는 “세부지침”이 우선하여 적용됩니다. 이 약관에서 정하지 아니한 사항이나 해석에 대해서는 “세부지침” 및 관련 법령 또는 상관례에 따릅니다.\n',
    },
    {
      title: '제4조 (용어의 정의)',
      content:[
        '①	이 약관에서 사용하는 용어의 정의는 다음과 같습니다.\n',
        '1.암호화폐: 비트코인(BTC), 이더리움(ETH) 등 블록체인에서 전자적으로 존재하는 화폐를 말합니다.\n',
        '2.사용자: 이 약관을 승인하고 회사가 제공하는 지갑을 등록한 이용자를 말합니다.\n',
        '3.앱: 암호화폐를 저장하거나 송수신 할 수 있는 스마트폰 온라인 전용 프로그램을 말합니다.\n',
        '4.지갑: 스마트폰에 암호화폐를 저장 할 수 있는 앱을 말합니다.\n',
        '5.비밀번호: 사용자와 지갑간의 동일성 확인과 암호화폐 보안을 위하여 사용자가 설정한 문자의 조합을 말합니다.\n',
        '6.복구단어: 지갑 분실시 복구할 수 있는 12개의 암호화된 단어를 말합니다.\n',
      ]
    },
    {
      title: '제2장 서비스 이용 신청 및 승낙 (사용자가입 및 해지)',
      content: '',
    },
    {
      title: '제5조 (이용계약의 성립)',
      content:[
        '①	이용자는 회사가 제공하는 앱에 지갑 생성 시 이 약관에 동의한다는 의사표시를 함으로서 지갑 사용을 신청합니다.\n',
        '②	이용 계약은 지갑을 등록한 단위로 체결합니다. 이용계약이 성립되면, 이용 신청자는 지갑 사용자로 등록됩니다.\n'
      ]
    },
    {
      title: '제6조 (이용신청)',
      content: '이용신청은 회사가 제공하는 앱에 지갑 등록시 자동으로 이용신청이 승인됩니다.\n',
    },
    {
      title: '제7조 (사용자정보 사용에 대한 동의 및 이용신청의 승낙)',
      content:[
        '① 사용자정보 사용에 대한 동의\n',
        '1.회사는 사용자의 개인정보를 본 이용계약의 이행과 본 이용계약상의 서비스제공을 위한 목적으로 이용합니다.\n',
        '2.사용자가 회사 및 회사와 제휴한 서비스들을 편리하게 이용할 수 있도록 하기 위해 사용자 정보는 회사와 제휴한 업체에 제공될 수 있습니다. 단, 회사는 사용자 정보 제공 이전에 제휴 업체, 제공 목적, 제공할 사용자 정보의 내용 등을 사전에 공지하고 사용자의 동의를 얻어야 합니다.\n',
        '3.사용자는 사용자 정보 수정을 통해 언제든지 개인 정보에 대한 열람 및 수정을 할 수 있습니다.\n',
        '4.사용자가 이용신청서에 사용자정보를 기재하고, 회사에 본 약관에 따라 이용신청을 하는 것은 회사가 본 약관에 따라 이용신청서에 기재된 사용자정보를 수집, 이용 및 제공하는 것에 동의하는 것으로 간주됩니다.\n',
      ]
    },
    {
      title: '제8조 (이용계약의 중지 및 해지)',
      content:[
        '①이용계약 및 앱 사용의 해지는 사용자가 스마트폰에서 앱을 삭제함으로써 해지가 가능합니다.\n',
        '② 회사는 가입신청자의 신청에 대하여 아래 각 호에 해당하는 사유가 있는 경우에는 승낙을 하지 않을 수 있으며, 가입 이후에도 아래 각 호의 사유가 확인될 경우에는 승낙을 취소하거나 이용계약을 해지할 수 있습니다.\n',
        '1. 본 약관에 의하여 이전에 회원자격을 상실한 적이 있는 경우\n',
        '2. 허위 또는 잘못된 정보를 기재 또는 제공하거나, 회사가 제시하는 내용을 기재하지 않은 경우\n',
        '3. 가입신청자의 귀책사유로 인하여 승인이 불가능하거나 기타 본 약관에서 규정한 제반 사항을 위반하며 신청한 경우\n',
        '4. 부정한 용도 또는 별개의 영업 목적으로 서비스를 이용하고자 하는 경우\n',
        '5. 관련법령에 위배되거나 사회의 안녕질서 혹은 미풍양속을 저해할 수 있는 목적으로 신청한 경우\n',
        '6. 가입신청자의 나이가 만 19세 미만인 경우\n',
        '③ 회사는 회원이 제공한 정보가 사실과 일치하는 지 여부를 확인하기 위하여 법령에 의하여 허용된 범위에서 전문기관을 통한 실명확인 또는 본인인증을 요청할 수 있습니다.\n',
      ]
    },
    { title: '제3장 회사의 의무', content: '' },
    {
      title: '제9조 사용자 개인정보와 서비스에 대한 회사의 의무',
      content:[
        '① 회사는 관련 법령과 본 약관을 준수하며, 계속적이고 안정적으로 서비스를 제공하기 위하여 최선을 다하여 노력합니다.\n',
        '② 회사는 사용자가 안전하게 서비스를 이용할 수 있도록 개인정보보호를 위해 보안시스템을 갖출 수 있으며, 개인정보처리방침을 공시하고 준수합니다.\n',
        '③ 회사는 업무와 관련하여 사용자의 사전 동의 없이 사용자 전체 또는 일부의 개인정보 등을 특정 사용자의 정보를 구별할 수 없는 통계자료로 작성하여 이를 사용할 수 있고, 이를 위하여 사용자는 컴퓨터에 쿠키를 전송할 수 있습니다. 다만, 이 경우 사용자는 쿠키의 송신을 거부하거나 쿠키의 송신에 대하여 경고하도록 사용하는 기기의 설정을 변경할 수 있으나, 이 경우 쿠키의 설정 변경으로 인해 회사의 귀책사유 없이 서비스 이용이 변경될 수 있습니다.\n',
        '④ 회사는 서비스 이용과 관련하여 사용자로부터 제기된 의견이나 불만이 정당하다고 인정할 경우에는 이를 처리하여야 하며, 서비스 내 게시판, E-mail 등을 통하여 사용자에게 처리과정 및 결과를 전달할 수 있습니다.\n',
        '⑤ 회사는 정보통신망법, 위치정보보호법, 통신비밀보호법, 전기통신사업법 등 서비스의 운영, 유지와 관련 있는 법규를 준수합니다.\n',
      ]
    },
    { title: '제4장 사용자의 의무', content: '' },
    {
      title: '제10조 (사용자 지갑과 비밀번호 관리에 대한 사용자의 의무)',
      content:[
        '① 지갑과 비밀번호에 관한 모든 관리책임은 사용자에게 있습니다. 사용자가 등록한 지갑과 비밀번호의 관리소홀, 부정사용에 의하여 발생하는 모든 결과에 대한 책임은 사용자에게 있으며, 회사는 이에 대한 책임을 지지 않습니다. 다만, 회사의 고의로 인한 경우에는 그러하지 아니합니다.\n',
        '② 12개의 복구 단어는 반드시 종이에 기입 해 놓아야 하며 지갑을 분실하여 복구 할때 12개의 복구 단어가 필요합니다. 회사는 이 정보를 가지고 있지 않기 때문에 복구단어가 분실하는 경우에 복구가 불가능한데 이때 모든 책임은 사용자에게 있으며 회사는 이에 대한 책임을 지지 않습니다. 또한 본인의 실수로 인해 12개의 복구 단어가 유출되어 입은 손실도 모든 책임은 사용자에게 있으며 회사는 이에 대한 책임을 지지 않습니다.\n',
      ]
    },
    {
      title: '제11조 (정보의 제공)',
      content:[
        '1. 회사는 유∙무선전화, 전자우편, 문자서비스(LMS/SMS), SNS 등의 방법으로 다음과 같은 서비스에 대해서 이용편의를 위한 이용안내 및 상품에 관한 정보를 제공할 수 있습니다. 이 경우 사용자는 언제든지 수신을 거부할 수 있습니다.\n',
        '① 이벤트 및 행사관련 등의 서비스\n',
        '② 기타 회사가 수시로 결정하여 사용자에게 제공하는 서비스\n',
        '2. 사용자는 서비스를 통하여 언제든지 본인의 개인정보를 열람하고 수정 또는 수정 요청할 수 있습니다. 사용자는 회원가입 신청 시 기재한 사항이 변경되었을 경우 서비스에서 직접 수정하거나, 고객센터를 통하여 회사에 변경 사항을 통지하여야 합니다. 사용자는 전항의 변경사항을 회사에 통지하지 않아 발생한 불이익에 대하여 회사는 책임을 지지 않습니다.\n',
      ]
    },
    { title: '제5장 서비스 이용 총칙', content: '' },
    {
      title: '제12조 (서비스의 종류)',
      content:[
        '① 회사에서 제공하는 서비스에는 암호화폐를 저장하는 지갑기능, 다른 사람에게 암호화폐를 송수신 할수 있는 기능 등이 있습니다. 단, 사용자가 제공하는 암호화폐 지갑 주소의 정확성에 대한 책임은 사용자에게 있으며, 회사는 사용자가 잘못 기재한 암호화폐 지갑 주소로 암호화폐를 보내는 것에 대해서 책임을 지지 않습니다.\n',
        '② 회사가 제공하는 서비스의 종류는 회사의 사정에 의하여 수시로 변경될 수 있으며, 제공되는 서비스에 대한 저작권 및 지식재산권은 회사에 귀속됩니다.\n',
        '③ 회사는 서비스와 관련하여 사용자에게 회사가 정한 이용조건에 따라 계정, 지갑, 서비스 등을 이용할 수 있는 이용권한만을 부여하며, 사용자는 이를 활용한 유사 서비스 제공 및 상업적 활동을 할 수 없습니다.\n',
      ]
    },
    {
      title: '제13조 (서비스 내용의 공지 및 변경)',
      content:[
        '① 회사는 서비스의 종류에 따라 각 서비스의 특성, 절차 및 방법에 대한 사항을 웹사이트를 통하여 공지하며, 사용자는 회사가 공지한 각 서비스에 관한 사항을 이해하고 서비스를 이용해야 합니다.\n',
        '② 회사는 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라 제공하고 있는 개별 서비스의 전부 또는 일부를 변경할 수 있습니다. 이 경우 회사는 최소 7일전에 해당 내용을 이용자에게 알립니다.\n',
      ]
    },
    {
      title: '제14조 (서비스의 유지 및 중지)',
      content:[
        '① 서비스의 이용은 회사의 업무상 또는 기술상 특별한 지장이 없는 한 1일 24시간을 원칙으로 합니다. 다만 정기 점검 등의 필요로 회사가 사전 혹은 사후에 고지하여 정한 날이나 시간은 그러하지 않습니다.\n',
        '② 회사는 서비스를 일정범위로 분할하여 각 범위 별로 이용가능 시간을 별도로 정할 수 있습니다. 이 경우 그 내용을 사전 혹은 사후에 공지합니다.\n',
        '③ 회사는 다음 각 호에 해당하는 경우 서비스 제공을 중지할 수 있습니다. 이 경우 회사는 불가피한 경우가 아닌 한 서비스 제공 중지를 사전 고지합니다.\n',
        '1. 서비스용 설비의 보수 등 공사로 인한 부득이한 경우\n',
        '2. 전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를 중지했을 경우\n',
        '3. 회사가 직접 제공하는 서비스가 아닌 제휴업체 등의 제3자를 이용하여 제공하는 서비스의 경우 제휴 업체 등의 제3자가 서비스를 중지했을 경우\n',
        '4. 기타 불가항력적 사유가 있는 경우\n',
        '④ 회사는 국가비상사태, 정전, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 때에는 서비스의 전부 또는 일부를 제한하거나 정지할 수 있습니다.\n',
      ]
    },
    {
      title: '제 15 조 이용제한',
      content:[
        '① 회사는 다음 각 호에 해당하는 경우 회원의 서비스 이용 및 접속을 제한할 수 있습니다.\n',
        '1. 비밀번호 및 보안비밀번호 연속 오류의 경우\n',
        '2. 해킹 및 사고가 발생한 경우\n',
        '3. 명의 도용으로 의심되는 경우\n',
        '4. 국가기관이 관련 법령에 따라 서비스 제한을 요청하는 경우\n',
        '5. 자금세탁, 불공정거래, 범죄행위 등에 관여하고 있거나 관여하고 있다고 합리적으로 의심되는 경우\n',
        '6. 회원으로 가입된 이용자가 만 19세 미만으로 확인된 경우\n',
        '7. 기타 각호에 준하는 사유가 발생하거나 그러한 사유 발생의 방지를 위한 조치가 필요한 경우\n',
        '② 회사는 다음 각 호에 해당하는 경우 회원의 입금 및 출금 이용을 제한할 수 있습니다.\n',
        '1. 회원으로 가입된 이용자가 만 19세 미만으로 확인된 경우\n',
        '2. 해킹 및 사기 사고가 발생하였거나 발생한 것으로 의심되는 경우\n',
        '3. 명의 도용으로 의심되는 경우\n',
        '4. 국가기관이 관련 법령에 따라 서비스 제한을 요청하는 경우\n',
        '5. 자금세탁, 불공정거래, 범죄행위 등에 관여하고 있거나 관여하고 있다고 합리적으로 의심되는 경우\n',
        '6. 회원 본인의 요청이 있는 경우\n',
        '7. 암호화폐가 송금되었으나 회원 계정에 반영되지 않은 경우 또는 실제 입출금 요청과 다르게 입출금이 이루어진 경우\n',
        '8. 기타 각호에 준하는 사유에 해당하는 사유가 발생하거나 그러한 사유 발생의 방지를 위한 조치가 필요한 경우\n',
        '③ 본 조에 따라 서비스 이용을 제한하거나 이용계약을 해지하는 경우에는 회사는 E-mail, SMS, App Push 등으로 회원에게 통지합니다.\n',
        '④ 회원은 본 조에 따른 이용제한 등에 대해 회사가 정한 절차에 따라 이의신청을 할 수 있습니다. 이 때 이의가 정당하다고 회사가 인정하는 경우 회사는 즉시 서비스의 이용을 재개합니다.\n',
      ]
    },
    {
      title: '제 16 조 (이용계약 해지)',
      content:[
        '① 사용자는 언제든지 고객센터를 통하여 이용계약 해지를 신청할 수 있으며, 회사는 관련 법령이 정하는 바에 따라 이를 즉시 처리하여야 합니다.\n',
        '② 회사는 회원에게 다음과 같은 사유가 발생할 경우 시간을 정하여 서비스 이용을 제한함과 동시에 시정요구를 할 수 있습니다. 시정요구에도 불구하고 상당한 기간 내에 시정되지 않거나 반복적으로 동일한 위반행위를 하는 경우에는 이용계약을 해지할 수 있습니다.\n',
        '1. 본 약관 제10조에 규정된 사용자의 의무를 위반하거나 제15조에 규정된 이용제한 사유에 해당하는 경우\n',
        '2. 저작권법을 위반한 불법 프로그램의 제공 및 운영 방해, 정보통신망 이용촉진 및 정보보호 등에 관한 법률을 위반한 불법 통신 및 해킹, 악성프로그램의 배포, 접속 권한 초과 행위 등과 같이 관련 법령을 위반한 경우\n',
        '3. 회사가 제공하는 서비스의 원활한 진행을 방해하는 행위를 하거나 시도한 경우\n',
        '4. 그 밖에 각 호에 준하는 사유로서 본 계약을 유지할 수 없는 사유가 발생하는 경우\n',
        '③ 전항에 따른 이용계약 해지 시 서비스 이용을 통해 획득한 모든 혜택이 소멸되며 회사는 이에 대해 별도로 보상하지 않습니다.\n',
        '④ 본 조에 따라 서비스 이용계약을 해지하는 경우에는 회사는 사용자에게 E-mail, SMS, App Push 등으로 통지합니다.\n',
        '⑤ 이용계약 해지가 완료되는 경우 관련 법령 및 개인정보처리방침에 따라 회사가 보유하여야 하는 정보를 제외한 사용자의 모든 정보가 삭제됩니다.\n',
        '⑥ 제2항에 따라 회사가 이용계약을 해지하는 경우 회사는 사용자의 이의신청 접수 및 처리 등을 위하여 일정 기간 동안 사용자의 정보를 보관할 수 있으며, 해당 기간이 경과한 후에 사용자의 정보(단, 입출금 내역은 제외)를 삭제합니다.\n',
      ]
    },
    {
      title: '제 17 조 (정보의 제공 및 광고의 게재)',
      content:[
        '① 회사는 서비스를 운영함에 있어서 서비스 관련 각종 정보를 서비스 화면에 게재하거나 E-mail, SMS, App Push 등의 방법으로 사용자에게 제공할 수 있습니다.\n',
        '② 회사는 서비스를 운영함에 있어 회사 또는 제휴사의 각종 광고를 서비스 화면에 게재하거나 사용자의 동의를 얻어 E-mail, SMS, App Push 등의 방법으로 사용자에게 제공할 수 있습니다',
      ]
    },
    { title: '제5장 개인정보 보호', content: '' },
    {
      title: '제18조 (개인정보의 보호)',
      content:[
        '회사는 관계 법령이 정하는 바에 따라 사용자의 개인정보를 보호하기 위해 노력합니다. 사용자의 개인정보는 서비스의 원활한 제공을 위하여 사용자가 동의한 목적과 범위 내에서만 이용됩니다. 회사는 법령에 의하거나 사용자가 별도로 동의하지 아니하는 한, 사용자의 개인정보를 제3자에게 제공하지 아니하며, 이에 대한 자세한 사항은 개인정보 처리방침에서 정합니다.\n',
      ]
    },
    { title: '제6장 손해배상 및 면책조항', content: '' },
    {
      title: '제19조 (회사의 면책사항 및 손해배상)',
      content:[
        '① 회사는 서비스와 관련하여 이 약관에 명시되지 않은 어떠한 사항에 대하여 보증을 하지 않습니다. 또한, 회사는 회사가 발행하거나 지급을 보증하지 아니한 암호화폐의 가치를 보증하지 않습니다.\n',
        '② 회사는 천재지변, 디도스(DDos) 공격, IDC장애, 서비스 접속의 폭등으로 인한 서버 다운, 기간통신사업자의 회선 장애 등 기타 불가항력적인 사유로 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다. 다만, 회사의 고의 또는 중과실에 의한 경우에는 그러하지 아니합니다.\n',
        '③ 회사는 블록체인의 문제, 암호화폐 발행 관리 시스템 자체의 하자 또는 기술적 문제, 통신서비스 업체의 불량, 정기적인 서버점검 등으로 인하여 불가피하게 장애가 발생하였을 경우에 책임을 지지 않습니다. 다만, 회사의 고의 또는 중과실에 의한 경우에는 그러하지 아니합니다.\n',
        '④ 회사는 사용자의 귀책사유로 인한 서비스 이용 장애나 그 결과에 대하여는 책임을 지지 않습니다. 다만, 사용자에게 정당한 사유가 있는 경우에는 그러하지 아니합니다.\n',
        '⑤ 회사는 사용자간 또는 사용자과 제3자 상호간에 서비스를 매개로 하여 거래 등을 한 경우에는 책임이 면제됩니다. 다만, 회사의 고의 또는 중과실에 의한 경우에는 그러하지 아니합니다.\n',
        '⑥ 회사는 무료로 제공되는 서비스 이용과 관련하여 관련법에 특별한 규정이 없는 한 책임을 지지 않습니다.\n',
        '⑦ 회사는 안정적인 서비스 제공을 위해 정기적, 비정기적 또는 긴급한 서버 점검을 실시할 수 있습니다.\n',
        '⑧ 누구든지 서비스 오류, 전산장애 또는 기타 사유로 인하여 제3자 소유의 암호화폐를 권한 없이 취득하거나 전송 받게 될 경우, 회사는 당사자에게 사전 통지 후 해당 암호화폐를 회수하거나 원상회복 시키는 등 필요한 조치를 취할 수 있습니다.\n',
      ]
    },
    {
      title: '제20조 (관할법원 및 준거법)',
      content:[
        '① 본 서비스 이용 약관은 대한민국 법률에 따라 규율되고 해석되며, 회사의 요금체계 등 서비스 이용과 관련하여 회사와 사용자 간에 발생한 분쟁으로 소송이 제기되는 경우 대한민국 법률이 적용됩니다.\n',
        '② 서비스 이용과 관련하여 회사와 사용자 간에 발생한 소송의 경우 법령에 정한 절차에 따른 법원을 관할 법원으로 합니다.\n',
      ]
    },
    { title: '부칙', content: '본 약관은 2021년 8월 1일부터 적용됩니다.' },
  ],  

};

export default string;
