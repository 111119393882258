import { Form, Input, Checkbox, Button, Modal } from 'antd';
import React from 'react';
import { httpGet, httpUrl, httpPost } from "../api/httpClient";
import { Route, withRouter } from "react-router-dom";

const FormItem = Form.Item;

class ModifyPassword extends React.Component {
    formRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    componentDidMount() {
        let pathname = this.props.location.pathname.split("/");
        if (pathname.length != 4) this.props.history.push('/')
        this.token = pathname[3];
        if (!this.token || this.token == "") this.props.history.push('/')
    }
    handleSubmit = (values) => {
        const { language, string } = this.props;
        console.log('handleSubmit');

        values.token = this.token;

        console.log(values);
       
        httpPost(httpUrl.changePassword, [], values).then((result) => {
            result = result.data;

            if (result.result) {
                console.log("## success");
                this.formRef.current.resetFields();
                alert('비밀번호가 변경되었습니다.')

            } else {
                console.log("## fail");
                alert('비밀번호가 변경에 실패하였습니다. 잠시 후 다시 시도해주세요')

            }
            // if (dlg != null) dlg.dismiss();

        }).catch(e => {
            // if (dlg != null) dlg.dismiss();

            console.log('## error: ' + e);
            alert('비밀번호가 변경에 실패하였습니다. 잠시 후 다시 시도해주세요')
            // if (callback instanceof Function) callback(false, '');
        });
    }
    render() {
        const { language, string } = this.props;
        return (
            <div className="wrapper">
                <div className="header">
                    안녕하세요
                </div>
                <div className="container">
                    <div className="password-modify-title">비밀번호 변경</div>
                    
                    <Form onFinish={this.handleSubmit} className="form" ref={this.formRef}>
                        <div>
                            <div className="login-field-icon">
                                {/* <img src={require('../img/login/password.png')} /> */}
                            </div>
                            <div className="login-field-input">
                                <Form.Item
                                    name="newPassword"
                                    rules={[
                                        ({ getFieldValue }) => ({
                                            validator(rule, value) {
                                                let strongRegex = new RegExp(
                                                    '^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9]).{8,15}$'
                                                );
                                                if (!value || value == '') return Promise.reject('비밀번호를 입력해주세요');
                                                else if (!strongRegex.test(value)) return Promise.reject('8~15자 문자, 숫자, 특수문자를 입력해주세요.');
                                                return Promise.resolve();
                                            },
                                        }),
                                    ]}
                                >
                                    <Input type="password" className="input2"
                                        placeholder={'비밀번호'}
                                    />
                                </Form.Item>
                            </div>

                        </div>
                        <div>

                            <div className="login-field-icon">
                                {/* <img src={require('../img/login/password.png')} /> */}
                            </div>
                            <div className="login-field-input">
                                <Form.Item
                                    name="passwordConfirm"
                                    rules={[
                                        ({ getFieldValue }) => ({
                                            validator(rule, value) {
                                                if (!value || value == '') return Promise.reject('비밀번호 확인을 입력해주세요.');
                                                else if (getFieldValue('newPassword') != value) return Promise.reject('비밀번호와 비밀번호 확인을 동일하게 입력해주세요.');
                                                else return Promise.resolve();
                                            },
                                        }),
                                    ]}
                                >
                                    <Input type="password" className="input2"
                                        placeholder={'비밀번호확인'}
                                    />
                                </Form.Item>
                            </div>
                        </div>
                        <div>

                            <div className="signupbtn">
                                <Form.Item>
                                    <Button className="signup-btn signup-btn-ok" type="primary" htmlType="submit">{'확인'}</Button>
                                </Form.Item>
                            </div>
                        </div>

                    </Form>
                </div>
            </div>
        )
    }
}

export default withRouter(ModifyPassword);