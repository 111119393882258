export default {
  serverProtocol: 'https',
  serverIp: 'back.1.com',
  serverPort: '18001',
  serverContext: '',
  // serverProtocol: 'http',
  // serverIp: '192.168.0.25',
  // serverPort: '18001',
  // serverContext: '',

  appCode: 'bitlive'
};
